/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import FooterPage from "./footer"
import "./primeSagaBlue.css"
import "./primeflex.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        crossOrigin="anonymous"
      />
      <link rel="stylesheet" href="./primeflex.css" />
      <link rel="stylesheet" href="./primeSagaBlue.css" />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div id="main">
        <main>{children}</main>
      </div>
      <FooterPage />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
