import * as React from "react"

function FooterPage() {
  return (
    <footer className="bg-black-alpha-90 px-custom py-custom">
      <div className="content">
        <div className="grid text-center">
          <div className="xs:col-12 sm:col-12 md:col-5 col">
            <h1 className="text-50 uppercase text-xl">About Tronstride ERP</h1>
            <span className="text-400 text-base">
              Tronstride ERP provides the best business management solution for
              transforming your company to thrive in the new digital economy.
              Built on a future-proof platform with open architecture for rapid
              integrations, scalability, and ease of use, Tronstride delivers
              unparalleled value to small and midmarket organizations. Connected
              business. Delivered
            </span>
          </div>
          <div className="xs:col-12 sm:col-12 md:col-5 col">
            <h1 className="text-50 uppercase text-xl">Contact Information</h1>
            <div className="text-400 text-base">
              <i aria-hidden="true" className="fa fa-map-marker text-color"></i>
              <a
                className="text-300 no-underline map__card__link ml-2"
                href="https://www.google.com/maps/place/SPC+Free+Zone/@25.3199661,55.4558669,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f5f26195b7e6b:0xc0f6d4eb509bdf75!8m2!3d25.3199661!4d55.4580556"
                target="_blank"
                rel="noreferrer"
              >
                Sharjah Publishing City FreeZone
              </a>
            </div>
            <br />
            <div className="text-400 text-base">
              <i aria-hidden="true" className="fa fa-phone text-color"></i>
              <a className="text-50 ml-2" href="tel:+971 58 292 9678">
                +971 58 292 9678
              </a>
            </div>
            <br />
            <div className="text-400 text-base">
              <i aria-hidden="true" className="fa fa-envelope text-color"></i>
              <a className="text-pink-500 ml-2" href="mailto:sales@tronstride.com">
                sales@tronstride.com
              </a>
            </div>
          </div>
          <div className="xs:col-12 sm:col-12 md:col-2 col">
            <h1 className="text-50 uppercase text-xl">Follow Us</h1>
            <a href="https://www.linkedin.com/company/tronstride/"> 
              <i className="fa fa-linkedin footer-icon" aria-hidden='true'></i>
            </a>
            <a href="https://youtube.com/channel/UCZPGK8zSulTWX4LF_xMJ36A">
              <i className="fa fa-youtube-play footer-icon mx-2" aria-hidden='true'></i>
            </a>
          </div>
        </div>
        <hr className="text-50" />
        <div className="text-50 text-xs">
          Copyright &copy; Tronstride. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default FooterPage
