import { Link } from "gatsby"
import * as React from "react"
import logo from "../images/logo-hd.png"
import { useLocation } from '@reach/router';
import './header.css'
import { useState } from "react";

const Header = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const isHomepage = pathname === '/'
  const isAboutpage = pathname === '/about'
  const isServicepage = pathname === '/service'
  const isContactpage = pathname === '/contact'

  return (
    <header className={`header ${isOpen ? 'header-open' : ''}`}>
      <img className="logo" src={logo} alt="logo" />
      <i aria-hidden="true" 
             onClick={() => setIsOpen(!isOpen) } 
             className={`menu-icon fa text-center text-0 text-lg cursor-pointer ${isOpen ? 'fa-close' : 'fa-bars'}`}></i>
      <div className="header-right">
        <div className="nav-div flex align-items-center">
          <nav className={`navbar text-lg ${isOpen ? 'block' : 'xs:hide'}`}>
            <Link to="/" className={`nav-link ${isHomepage ? "active" : ""}`}>
              Home
            </Link>
            <Link
              to="/about"
              className={`nav-link ${isAboutpage ? "active" : ""}`}
            >
              Company
            </Link>
            <Link
              to="/service"
              className={`nav-link ${isServicepage ? "active" : ""}`}
            >
              Service
            </Link>
            <Link
              to="/contact"
              className={`nav-link ${isContactpage ? "active" : ""}`}
            >
              Contact us
            </Link>
          </nav>
       </div>
      </div>
    </header>
  )
}
export default Header
